body {
  overflow-x: hidden !important;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype"),
    url("./assets/fonts/SpaceGrotesk-Light.ttf") format("truetype"),
    url("./assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype"),
    url("./assets/fonts/SpaceGrotesk-Regular.ttf") format("truetype"),
    url("./assets/fonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --primary-color: #fbbf24;
  --background-color: #000;
  --font-family: "Space Grotesk", sans-serif;
  --border-radius: 8px;
  --transition-duration: 0.3s;
}

body {
  font-family: var(--font-family);
  background: radial-gradient(
    circle 1200px at center,
    #3e0029,
    var(--background-color)
  );
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden !important;
}

body::before,
body::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px dashed rgba(251, 191, 36, 0.5);
  border-radius: 50%;
  box-sizing: border-box;
  z-index: -1;
  pointer-events: none;
  overflow-x: hidden !important;
}

body::before {
  width: 400px;
  height: 400px;
}

body::after {
  width: 800px;
  height: 800px;
}

.logo img {
  width: 14vw;
}

.bm-burger-button {
  width: 36px;
  height: 30px;
  cursor: pointer;
}

.bm-item-list {
  display: block;
}

#react-burger-menu-btn {
  width: unset !important;
  height: unset !important;
}

.bm-item-list a {
  text-decoration: none;
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
  letter-spacing: -0.48px;
  text-align: end;
}

.bm-item-list a:hover {
  color: #000;
}

header {
  background: var(--background-color);
  padding: 18px 24px;
  position: sticky;
  top: 0;
  z-index: 999;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.menu-burger {
  color: #fff !important;
}

.card {
  border: 1px solid #ccc;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.line {
  height: 1px;
  width: 100%;
  background: #dadada;
  border-radius: 32px;
  margin-bottom: 16px;
}

.star-filled {
  color: var(--primary-color);
}

.star-unfilled {
  color: #ccc;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slider-section {
  background: transparent;
  position: relative;
  overflow: hidden;
  margin: 0;
}

.content-home-slider {
  position: relative;
  padding: 12px;
  border-radius: var(--border-radius);
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.slider-img-home {
  padding: 12px;
  background: transparent;
}

.count-number {
  font-size: 10vw;
  color: var(--primary-color);
  font-weight: 900;
  position: relative;
  z-index: 0;
}

.card-quartile {
  background: transparent;
  padding: 24px 20px;
  border-radius: 12px;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.title-quartile {
  position: relative;
  z-index: 1;
}

.vertical-timeline-element-title {
  font-size: 24px !important;
  font-weight: 900 !important;
}

.vertical-timeline-element-content p {
  font-size: 32px !important;
  font-weight: 700 !important;
  letter-spacing: -0.2px !important;
  line-height: 48px !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arrow-top {
  transform: rotate(-180deg);
}

.arrow-bottom {
  transform: rotate(180deg);
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  background: #282828 !important;
  border: 1px solid #282828;
}

.animate-move-down {
  transition: transform var(--transition-duration) ease;
}

.animate-move-down:hover {
  transform: translateY(10px);
  background: var(--primary-color);
}

.takeAfree {
  border: 0;
  padding: 12px 24px;
  border-radius: var(--border-radius);
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  transition: transform var(--transition-duration) ease;
}

.takeAfree:hover {
  transform: translateY(-10px);
  background: var(--primary-color);
}

.downloadSample {
  border: 0;
  padding: 12px 24px;
  border-radius: var(--border-radius);
  background: var(--primary-color);
  color: #000;
  font-size: 14px;
  font-weight: 600;
  transition: transform var(--transition-duration) ease;
}

.downloadSample:hover {
  transform: translateY(-10px);
  background: #fff;
}

.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-duration),
    visibility var(--transition-duration);
  z-index: 1000;
}

.scroll-to-top-button.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top-button button {
  background-color: rgba(251, 191, 36, 0.3);
  color: #fff;
  border: none;
  border-radius: var(--border-radius);
  width: 3rem; /* Changed to rem for responsiveness */
  height: 3rem; /* Changed to rem for responsiveness */
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform var(--transition-duration) ease;
}

.scroll-to-top-button button:hover {
  background-color: rgba(251, 191, 36, 0.5);
  transform: translateY(-10px);
}

/* Swiper styles */
.mySwiper {
  background-color: transparent;
}

.custom-slide {
  background-color: transparent;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  .container {
    padding: 0 20px;
  }

  .content-home-slider {
    height: 40vh;
  }

  .count-number {
    font-size: 8vw;
  }
}

@media (max-width: 768px) {
  header {
    padding: 40px 20px;
  }

  .bm-item-list a {
    font-size: 24px;
    line-height: 40px;
  }

  .content-home-slider {
    height: 30vh;
  }

  .count-number {
    font-size: 6vw;
  }
  body::before,
  body::after {
    position: relative !important;
    content: none;
  }
  .logo img {
    width: 160px !important;
  }
}

@media (max-width: 480px) {
  .bm-item-list a {
    font-size: 36px;
    line-height: 54px;
  }

  .content-home-slider {
    height: 25vh;
  }

  .count-number {
    font-size: 4vw;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.step-diagram {
  position: relative;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
}

.steps-container {
  display: flex;
  flex-direction: column;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  color: white;
  border-radius: 5px;
}

.step-content {
  flex-grow: 1;
}

.step h3 {
  margin: 0;
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #282828;
}

.step p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.step-number {
  font-size: 24px;
  font-weight: bold;
  margin-left: 15px;
}

.arrow {
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  width: 20px;
  background: linear-gradient(
    to top,
    rgba(251, 191, 36, 0.4),
    rgba(251, 191, 36, 0.6),
    rgba(251, 191, 36, 0.8),
    rgba(251, 191, 36, 1)
  );
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 85%, 0 100%);
}

@media (min-width: 768px) {
  .steps-container {
    flex-direction: column-reverse;
  }

  .step {
    width: calc(100% - 60px);
    margin-left: 60px;
  }

  .arrow {
    left: 0;
    width: 60px;
  }

  .step-number {
    position: absolute;
    left: -80px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #333;
    border-radius: 5px 0 0 5px;
  }
}

@media (max-width: 768px) {
  .arrow {
    display: none;
  }
}

@media (max-width: 768px) {
  .bm-menu-wrap {
    width: 100% !important;
  }

  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    position: unset !important;
  }

  html {
    overflow-x: auto;
  }
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #333;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
