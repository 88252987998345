@tailwind base;
@tailwind components;
@tailwind utilities;

.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}
.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
